import { format, parseISO } from 'date-fns';

export const getTimeZeroedOutUTCString = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  // Create a new date object in UTC, set time to 00:00:00:00
  const utcDate = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

  return utcDate.toISOString();
};

export const convertDateToFormattedString = (date: Date | string) => {
  const dateToFormat = typeof date === 'string' ? new Date(date) : date;

  return format(dateToFormat, 'yyyy-MM-dd');
};

export const parseDate = (date: string): Date => {
  return parseISO(date.split('T')[0]);
};
