import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { CustomCellEditorProps } from '@ag-grid-community/react';
import { IconInfoCircle } from '@tabler/icons-react';
import { addDays, format, max, parseISO, startOfToday } from 'date-fns';
import { DatePicker, Text } from '@ui/core';
import { type RowData } from '../../types';
import { useProductDataGridFormContext } from '../../useProductDataGridForm';

export const dateFormatString = 'yyyy-MM-dd';
const today = startOfToday();
export const defaultDeliveryWindow = {
  deliveryStartDate: today,
  deliveryEndDate: addDays(today, 90),
};

const DeliveryDateCellEditor = memo(
  ({ value, onValueChange, stopEditing, data }: CustomCellEditorProps<RowData>) => {
    const refContainer = useRef<HTMLDivElement>(null);

    const { getValues } = useProductDataGridFormContext();

    useEffect(() => {
      refContainer.current?.focus();
    }, []);

    const values = {
      type: data.type,
      brandId: data.brandId,
      uniqueRowId: data.uniqueRowId,
      collections: data.type === 'primaryVariantRow' ? data.collections : undefined,
      appliedCollectionId: data.type === 'primaryVariantRow' ? data.appliedCollectionId : undefined,
      indentOnly: data.type === 'primaryVariantRow' ? data.indentOnly : undefined,
    };

    const pricingOptionSelected = useMemo(() => {
      const collection = values.collections?.find(
        option => option.id === values.appliedCollectionId
      );
      // must select a collection if indent only
      return !(values.indentOnly && !collection?.id);
    }, [values.appliedCollectionId, values.collections, values.indentOnly]);

    const deliveryWindow = useMemo(() => {
      if (
        pricingOptionSelected &&
        values.type === 'primaryVariantRow' &&
        values.collections &&
        values.appliedCollectionId !== undefined
      ) {
        const deliveryWindows = getValues(
          `brands.brand_${values.brandId}.primaryVariants.${values.uniqueRowId}.deliveryWindows`
        );
        const dates = deliveryWindows[values.appliedCollectionId ?? 'default'];
        const dw = [today];
        if (dates[0]) {
          dw[0] = max([dw[0], dates[0]]);
        }
        if (dates[1]) {
          dw.push(dates[1]);
        }
        return dw;
      }
      return [today];
    }, [
      pricingOptionSelected,
      values.type,
      values.collections,
      values.appliedCollectionId,
      values.brandId,
      values.uniqueRowId,
      getValues,
    ]);

    const selectedCollection = useMemo(() => {
      if (values.type === 'primaryVariantRow' && values.collections) {
        return values.collections.find(option => option.id === values.appliedCollectionId);
      }
    }, [values.appliedCollectionId, values.collections, values.type]);

    const inSeasonCollectionSelected = selectedCollection?.type === 'in-season';

    const onChange = useCallback(
      (selectedDate: Date | null) => {
        if (selectedDate) {
          const dateISOString = format(selectedDate, dateFormatString);
          onValueChange(dateISOString);
          stopEditing();
        }
      },
      [onValueChange, stopEditing]
    );

    return (
      <div ref={refContainer} className="border border-andisor-blue bg-white p-2">
        <Text className="mb-2 flex w-52 items-center gap-2 text-xs">
          <IconInfoCircle size={16} stroke={1.5} className="shrink-0" />
          {deliveryWindow.length > 1 || inSeasonCollectionSelected
            ? 'Choose your preferred delivery date. Suppliers will aim to deliver before this date.'
            : 'Select a pricing option to view delivery dates'}
        </Text>
        <DatePicker
          type="default"
          value={value ? parseISO(value) : undefined}
          minDate={deliveryWindow[0]}
          maxDate={deliveryWindow[1]}
          onChange={onChange}
          size="xs"
          hideOutsideDates
        />
      </div>
    );
  }
);

export default DeliveryDateCellEditor;
