import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { useForm } from '@components/ReactHookForm/Form';
import { StoresSchema } from '@api/buyers/order-form';
import { parseDate } from '@utils/date';
import { defaultDeliveryWindow } from './cells/DeliveryDateCell/DeliveryDateCellEditor';
import { type BrandDetailsLookupType, type RowData } from './types';

export const ProductDataGridCollectionSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    additionalDiscount: z.number(),
    hasOrderWindow: z.boolean(),
    orderStartDate: z.string().nullable(),
    orderEndDate: z.string().nullable(),
    deliveryStartDate: z.string().nullable(),
    deliveryEndDate: z.string().nullable(),
    type: z.enum(['scheduled', 'in-season', 'indent']),
  })
);

const ProductDataGridFormSchema = z
  .object({
    storeIds: z.array(z.string()),
    stores: StoresSchema,
    brands: z.record(
      z.object({
        brandId: z.number(),
        isRemoved: z.boolean(),
        moq: z.number(),
        skipMoqValidation: z.boolean(),
        primaryVariants: z.record(
          z
            .object({
              productType: z.union([z.string(), z.null()]),
              heroCategory: z.union([z.string(), z.null()]),
              rowStatus: z.union([z.enum(['added', 'removed']), z.null()]),
              skipStockCheck: z.boolean(),
              variantValue: z.union([z.string(), z.null()]),
              colourPill: z.union([
                z.object({
                  name: z.string(),
                  hex: z.union([z.string(), z.null()]),
                }),
                z.null(),
              ]),
              indentOnly: z.boolean(),
              parentUniqueRowId: z.string(),
              uniqueRowId: z.string(),
              itemVariantId: z.number(),
              brandId: z.number(),
              productId: z.string(),
              variantId: z.string(),
              displayRrp: z.number(),
              displayWhs: z.number(),
              variantImageURL: z.union([z.string(), z.null()]),
              collections: ProductDataGridCollectionSchema,
              deliveryWindows: z.record(z.array(z.date())),
              appliedCollectionId: z.union([z.number(), z.null()]), // editable
              requestedFor: z.union([z.string(), z.null()]), // editable
              serviceError: z
                .object({
                  name: z.string(),
                  message: z.string(),
                })
                .optional(),
              whsMap: z.record(z.string(), z.number()),
              secondaryVariants: z.record(
                z
                  .object({
                    uniqueRowId: z.string(),
                    itemVariantId: z.number(),
                    itemSKUDetailId: z.union([z.number(), z.null()]),
                    brandId: z.number(),
                    productId: z.string(),
                    variantId: z.string(),
                    sku: z.string(),
                    stock: z.number().nonnegative(),
                    quantity: z.number().nonnegative(), // editable
                    storeToQuantityMap: z.record(z.number().nonnegative()),
                    value: z.string(),
                    skipStockCheck: z.boolean(),
                    rrp: z.number(),
                    whsMap: z.record(z.string(), z.number()),
                  })
                  .refine(data => data.skipStockCheck === true || data.stock >= data.quantity, {
                    message: 'Quantity cannot be greater than available stock',
                    path: ['quantity'],
                  })
              ),
            })
            .superRefine(
              (
                {
                  rowStatus,
                  requestedFor,
                  appliedCollectionId,
                  deliveryWindows,
                  indentOnly,
                  serviceError,
                  collections,
                },
                ctx
              ) => {
                // no validation required for removed rows
                if (rowStatus === 'removed') return true;

                if (serviceError) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: serviceError.message,
                    path: ['serviceError'],
                    fatal: true,
                  });
                }

                if (requestedFor === null) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `Required`,
                    path: ['requestedFor'],
                    fatal: true,
                  });
                }
                if (typeof requestedFor === 'string' && requestedFor.length > 0) {
                  // check if the date is within the delivery window
                  const dateObject = parseDate(requestedFor); // parse date in current tz
                  const dates = deliveryWindows[appliedCollectionId ?? 'default'];
                  const collection = collections.find(
                    option => option.id === Number(appliedCollectionId)
                  );

                  const hasSelectedDate = dates[0] <= dateObject && dateObject <= dates[1];
                  if (!hasSelectedDate && collection?.type !== 'in-season') {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: `Date outside delivery window`,
                      path: ['requestedFor'],
                      fatal: true,
                    });
                  }
                }
                if (indentOnly && !appliedCollectionId) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `Please select a pricing option`,
                    path: ['appliedCollectionId'],
                    fatal: true,
                  });
                }

                return true;
              }
            )
        ),
      })
    ),
  })
  .superRefine(({ brands, storeIds }, ctx) => {
    // get quantity for whole order form
    const stores: Record<string, number> = {};
    storeIds.forEach(storeId => {
      stores[storeId] = 0;
    });

    Object.keys(brands).forEach(brandKey => {
      const { primaryVariants } = brands[brandKey];

      // filter out removed rows
      const validPrimaryVariantsForBrand = Object.keys(primaryVariants).filter(
        key => primaryVariants[key].rowStatus !== 'removed'
      );

      // if no primary variants for brand (i.e all are removed), then moq validation is not required
      if (validPrimaryVariantsForBrand.length === 0) {
        return true;
      }

      validPrimaryVariantsForBrand.forEach(pvKey => {
        const pv = primaryVariants[pvKey];

        for (const svKey in pv.secondaryVariants) {
          const { storeToQuantityMap } = pv.secondaryVariants[svKey];

          if (storeIds && storeIds?.length > 0) {
            Object.keys(storeToQuantityMap).forEach(storeId => {
              const quantity = storeToQuantityMap[storeId];
              stores[storeId] += quantity;
            });
          }
        }
      });
    });

    // Brand validations
    Object.keys(brands).forEach(brandKey => {
      const { moq, brandId, skipMoqValidation, primaryVariants } = brands[brandKey];
      let totalPrice = 0;

      // filter out removed rows
      const validPrimaryVariantsForBrand = Object.keys(primaryVariants).filter(
        key => primaryVariants[key].rowStatus !== 'removed'
      );

      // if no primary variants for brand (i.e all are removed), then moq validation is not required
      if (validPrimaryVariantsForBrand.length === 0) {
        return true;
      }

      validPrimaryVariantsForBrand.forEach(pvKey => {
        let totalQtyForPV = 0;
        const svKeys: string[] = [];
        const pv = primaryVariants[pvKey];
        const { requestedFor } = pv;
        let aggregateStoreQty = 0;

        for (const svKey in pv.secondaryVariants) {
          svKeys.push(svKey);
          const { quantity, storeToQuantityMap, whsMap } = pv.secondaryVariants[svKey];
          const price = whsMap[pv.appliedCollectionId ?? '0'];
          totalQtyForPV += quantity;
          totalPrice += price * quantity;

          if (storeIds && storeIds?.length > 0) {
            Object.keys(storeToQuantityMap).forEach(storeId => {
              const quantity = storeToQuantityMap[storeId];
              aggregateStoreQty += quantity;
              stores[storeId] += quantity;
            });
          }
        }

        // this is for order proposal where storeIds would be 0
        if (storeIds?.length === 0 && requestedFor && totalQtyForPV === 0) {
          // ensure that atleast 1 size variant for pv has quantity if requestedFor is set
          svKeys.forEach(svKey => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Atleast 1 size variant should have quantity`,
              path: [
                'brands',
                brandKey,
                'primaryVariants',
                pvKey,
                'secondaryVariants',
                svKey,
                'quantity',
              ],
              fatal: true,
            });
          });
        }

        if (storeIds && storeIds?.length > 0 && aggregateStoreQty === 0) {
          svKeys.forEach(svKey => {
            // ensure that atleast 1 size variant for pv has quantity if requestedFor is set
            storeIds.forEach(storeId => {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Atleast 1 size variant should have quantity`,
                path: [
                  'brands',
                  brandKey,
                  'primaryVariants',
                  pvKey,
                  'secondaryVariants',
                  svKey,
                  'storeToQuantityMap',
                  storeId,
                ],
                fatal: true,
              });
            });
          });
        }
      });

      Object.keys(stores).forEach(storeKey => {
        const quantityPerStore = stores[storeKey];
        const isRemoved = !storeIds?.includes(storeKey);
        if (isRemoved) {
          return;
        }
        if (!quantityPerStore) {
          validPrimaryVariantsForBrand.forEach(pvKey => {
            const pv = primaryVariants[pvKey];
            for (const svKey in pv.secondaryVariants) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Each store should have atleast 1 quantity`,
                path: [
                  'brands',
                  brandKey,
                  'primaryVariants',
                  pvKey,
                  'secondaryVariants',
                  svKey,
                  'storeToQuantityMap',
                  storeKey,
                ],
                fatal: true,
              });
            }
          });
        }
      });

      if (!skipMoqValidation && totalPrice < moq) {
        // moq validation failed for brand
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `MOQ validation failed for brand: ${brandId}`,
          path: ['brands', brandKey, 'moq'],
          fatal: true,
        });
      }
    });

    return true;
  });

export type ProductDataGridFormType = z.infer<typeof ProductDataGridFormSchema>;

const setupProductDataGridFormDefaults = (
  brandDetailsLookup: BrandDetailsLookupType,
  rows: RowData[],
  skipMoqValidation: boolean
) => {
  const brands = Object.keys(brandDetailsLookup).reduce(
    (acc, brandId) => {
      acc[`brand_${brandId}`] = {
        isRemoved: false,
        brandId: brandDetailsLookup[brandId].id,
        moq: brandDetailsLookup[brandId].buyerTerms.moq,
        primaryVariants: {},
        skipMoqValidation,
      };
      return acc;
    },
    {} as Record<
      string,
      z.infer<typeof ProductDataGridFormSchema._def.schema.shape.brands.valueSchema>
    >
  );

  const makePrimaryVariant = (pvData: Extract<RowData, { type: 'primaryVariantRow' }>) => {
    return {
      rowStatus: null,
      variantValue: pvData.variantValue,
      colourPill: pvData.colourPill,
      indentOnly: pvData.indentOnly,
      parentUniqueRowId: pvData.parentUniqueRowId,
      uniqueRowId: pvData.uniqueRowId,
      itemVariantId: pvData.itemVariantId,
      brandId: pvData.brandId,
      productId: pvData.productId,
      variantId: pvData.variantId,
      variantImageURL: pvData.variantImageURL,
      displayRrp: pvData.displayRrp,
      displayWhs: pvData.displayWhs,
      whsMap: pvData.whsMap,
      collections: pvData.collections,
      deliveryWindows: {
        default: [defaultDeliveryWindow.deliveryStartDate, defaultDeliveryWindow.deliveryEndDate],
        ...(pvData.collections || []).reduce(
          (acc, collection) => {
            if (!collection.deliveryStartDate || !collection.deliveryEndDate) {
              acc[collection.id] = [];
              return acc;
            }
            acc[collection.id] = [
              parseDate(collection.deliveryStartDate),
              parseDate(collection.deliveryEndDate),
            ];
            return acc;
          },
          {} as Record<number, Date[]>
        ),
      },
      appliedCollectionId: null,
      requestedFor: null,
      skipStockCheck: false,
      heroCategory: null,
      productType: null,
      serviceError: undefined,
      secondaryVariants: {},
    };
  };
  const makeSecondaryVariant = (svData: Extract<RowData, { type: 'secondaryVariantRow' }>) => {
    return {
      uniqueRowId: svData.uniqueRowId,
      itemVariantId: svData.itemVariantId,
      itemSKUDetailId: svData.itemSKUDetailId,
      brandId: svData.brandId,
      productId: svData.productId,
      variantId: svData.variantId,
      sku: svData.sku,
      stock: svData.stock ?? 0,
      quantity: svData.quantity,
      value: svData.secondaryVariantValue,
      skipStockCheck: false,
      storeToQuantityMap: svData.storeToQuantityMap,
      rrp: svData.rrp,
      whsMap: svData.whsMap,
    };
  };

  const productMap: { [key: string]: Extract<RowData, { type: 'productRow' }> } = {};

  rows?.forEach((item: RowData) => {
    const { type } = item;
    const brandKey = `brand_${item.brandId}`;

    const primaryVariants = brands[brandKey].primaryVariants;

    switch (type) {
      case 'productRow': {
        productMap[item.uniqueRowId] = item;
        break;
      }
      case 'primaryVariantRow': {
        // setting the pv
        if (!primaryVariants[item.uniqueRowId]) {
          primaryVariants[item.uniqueRowId] = makePrimaryVariant(item);
        }
        // setting the selected pricing option and delivery date for pv
        primaryVariants[item.uniqueRowId].appliedCollectionId = item.appliedCollectionId ?? null;
        primaryVariants[item.uniqueRowId].requestedFor = item.requestedFor;
        primaryVariants[item.uniqueRowId].skipStockCheck =
          item.collections.find(option => option.id === item.appliedCollectionId)?.type ===
          'indent';
        primaryVariants[item.uniqueRowId].productType =
          productMap[item.parentUniqueRowId]?.productType;
        primaryVariants[item.uniqueRowId].heroCategory =
          productMap[item.parentUniqueRowId]?.heroCategory;
        primaryVariants[item.uniqueRowId].serviceError = item.error;
        break;
      }
      case 'secondaryVariantRow': {
        // setting the sv for pv
        if (
          item.parentUniqueRowId &&
          !primaryVariants[item.parentUniqueRowId].secondaryVariants[item.uniqueRowId]
        ) {
          primaryVariants[item.parentUniqueRowId].secondaryVariants[item.uniqueRowId] =
            makeSecondaryVariant(item);
          primaryVariants[item.parentUniqueRowId].secondaryVariants[
            item.uniqueRowId
          ].skipStockCheck = primaryVariants[item.parentUniqueRowId].skipStockCheck;
        }
        break;
      }
      default: {
        break;
      }
    }
  });

  return { brands, storeIds: [], stores: [] };
};

const defaultFormValues = {
  brands: {} as ProductDataGridFormType['brands'],
  storeIds: [] as ProductDataGridFormType['storeIds'],
  stores: [] as ProductDataGridFormType['stores'],
};

export const useProductDataGridForm = (
  brandDetailsLookup: BrandDetailsLookupType | undefined,
  rowData: RowData[] | undefined,
  skipMoqValidation: boolean = false
) => {
  const watchedValues = useMemo(() => {
    if (rowData && rowData.length > 0 && brandDetailsLookup) {
      const result = setupProductDataGridFormDefaults(
        brandDetailsLookup,
        rowData,
        skipMoqValidation
      );
      return result;
    }
    return defaultFormValues;
  }, [brandDetailsLookup, rowData, skipMoqValidation]);

  return {
    watchedValues,
    form: useForm({
      schema: ProductDataGridFormSchema,
      defaultValues: defaultFormValues,
      values: watchedValues,
      mode: 'onSubmit',
    }),
  };
};

export const useProductDataGridFormContext = () => useFormContext<ProductDataGridFormType>();
